// Gallery.jsx
import React from 'react';
import Layout from "../components/layout";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import {StaticImage} from "gatsby-plugin-image";

const GalleryAustalia1990 = () => {
    return (
        <Layout>
            <a href="/Gallery">Return to Gallery</a>
            <h1>Australia 1990 - 2000</h1>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia1990/1172501_orig.jpg"
                             alt="1990s Trophies ©Terry Lyon"/><StaticImage
                src="../images/gallery/Australia1990/1838270_orig.jpg" alt="Early 1990's"/><StaticImage
                src="../images/gallery/Australia1990/2076090_orig.jpg" alt="Early 1990's"/><StaticImage
                src="../images/gallery/Australia1990/3337622_orig.jpg"
                alt="1993 Sensei Jim Gloriod - Hojo undo ©Terry Lyon"/><StaticImage
                src="../images/gallery/Australia1990/4424256_orig.jpg"
                alt="1993 Dan Grading Sensei Jim Gloriod - Sensei Peter Hutchinson"/><StaticImage
                src="../images/gallery/Australia1990/4941990_orig.jpg"
                alt="1995.9.6 Dan grading ©Terry Lyon"/><StaticImage
                src="../images/gallery/Australia1990/5136281_orig.jpg" alt="Early 1990's"/><StaticImage
                src="../images/gallery/Australia1990/6944756_orig.jpg"
                alt="Early 1990's - Phil Yabsley, Sensei Graham Ravey, Sensei Bill Groves, Jo Burt"/><StaticImage
                src="../images/gallery/Australia1990/7125182_orig.jpg" alt="1993 WA Dan grading ©Terry Lyon"/>
            </SRLWrapper></SimpleReactLightbox>
        </Layout>
    );
}

export default GalleryAustalia1990;
